import {
    PaginationState,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
  } from "@tanstack/react-table";
  import Card from "components/card";
  import DivLoader from "components/divloader/DivLoader";
  import React, { useEffect, useState } from "react";
  import { ClientRowObj } from "..";
  import Searchbox from "components/fields/Searchbox";
  import Pagination from "components/pagination";
import ShortTruncateCopy from "components/common/ShortTruncateCopy";
import moment from "moment";
import { MdAdd, MdPlusOne, MdRemove } from "react-icons/md";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import { Column } from 'react-table';
import { getAllGateways } from "api/graphQL_gateways";

import visa from "assets/svg/card_type/visa.svg";
import MasterCard from "assets/svg/card_type/mastercard.svg";
import Discover from "assets/svg/card_type/discover.svg";
import Amex from "assets/svg/card_type/amex.svg";
import MaestroCard from "assets/svg/card_type/maestro.svg";
import DinersClub from "assets/svg/card_type/dinersclub.svg";
import JCB from "assets/svg/card_type/jcb.svg";
import UnionPay from "assets/svg/card_type/unionpay.svg";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from "@chakra-ui/react";
import { convertToFloat } from "utils/formatNumber";
import StatusRender from "views/admin/payments/components/StatusRender";
import AdvancedInfoModal from "views/admin/payments/components/AdvancedInfoModal";
import CustomToolTip from "components/tooltips";

  
  function CustomerTable(props: {
    tableData: any;
    fetchClients: () => void;
    isLoading: boolean;
    roleData?: any;
    page: number;
    setPage: any;
    totalpage: number;
    totalItems: number;
    currentPage: number;
    pageSize: number;
    setPageSize: any;
    allGateways: any;
    onValueChange: (value: string) => void;
  }) {
    const {
      tableData,
      fetchClients,
      roleData,
      page,
      setPage,
      currentPage,
      totalpage,
      totalItems,
      pageSize,
      setPageSize,
      allGateways,
      onValueChange,
      isLoading
    } = props;
    let defaultData = tableData;
  
    const [{ pageIndex }, setPagination] = React.useState<PaginationState>({
      pageIndex: 0,
      pageSize,
    });

    const [expandedRow, setExpandedRow] = useState<string | null>(null);
  
    const pagination = React.useMemo(() => ({
      pageIndex,
      pageSize,
    }), [pageIndex, pageSize],
    );

    const handleExpand = (rowId : any) => {
      setExpandedRow(expandedRow === rowId ? null : rowId);
    };
  
    const columns = [
      columnHelper.accessor("created_at", {
        id: "created_at",
        header: () => (
            <p className="text-sm font-bold text-gray-900 dark:text-white">Created Date</p>
        ),
        cell: (info : any) => {
            const rawDate = info.getValue();
            const formattedDate = moment(rawDate).format("YYYY-MM-DD HH:mm:ss");
    
            return (
              <p
                className="text-sm font-bold text-navy-700 dark:text-white flex items-center gap-1 cursor-pointer"
                onClick={() =>
                  handleExpand(info?.row?.original?.customer_id)
                }
              >
                
                  {expandedRow === info?.row?.original?.customer_id ? (
                    <AiFillMinusCircle className="w-5 h-5 text-navy-700 dark:text-white" />
                  ) : (
                    <AiFillPlusCircle className="w-5 h-5 text-navy-700 dark:text-white" />
                  )}
              
                {formattedDate}
              </p>
            );
        },  
        size: 200, 
        minSize: 200,
        maxSize: 300, 
      }),
      columnHelper.accessor("customer_id", {
        id: "customer_id",
        header: () => (
          <p className="text-sm font-bold text-gray-900 dark:text-white">CUSTOMER ID</p>
        ),
        cell: (info: any) => {
            return <>
              <ShortTruncateCopy info={info?.getValue()} showCopy={true} />
            </>
          },
      }),
      columnHelper.accessor("first_name", {
        id: "first_name",
        header: () => (
          <p className="text-sm font-bold text-gray-900 dark:text-white">NAME</p>
        ),
        cell: (info: any) => {
            return (
                <>
                    <div className="flex items-center">
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {info?.row?.original?.first_name} {info?.row?.original?.last_name}
                        </p>
                    </div>   
                </> 
            )
        },
      }),
      columnHelper.accessor("email", {
        id: "email",
        header: () => (
          <p className="text-sm font-bold text-gray-900 dark:text-white">Email</p>
        ),
        cell: (info) => (
            <p className="text-sm font-bold text-navy-700 dark:text-white">
              <a href={`mailto:${info.getValue()}`}> {info.getValue()}</a>
            </p>
        ),
      }),
      columnHelper.accessor("phone", {
        id: "phone",
        header: () => (
          <p className="text-sm font-bold text-gray-900 dark:text-white">PHONE</p>
        ),
        cell: (info) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            <a href={`tel:${info.getValue()}`}> {info.getValue()}</a>
            {/* {info.getValue()} */}
          </p>
        ),
      }),
      columnHelper.accessor("source_client", {
        id: "source_client",
        header: () => (
          <p className="text-sm font-bold text-gray-900 dark:text-white">SOURCE CLIENT</p>
        ),
        cell: (info: any) => {
            return <>
                <div className="flex items-center">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {info?.getValue()}
                    </p>
                </div>
            </>
        },
      }),
      columnHelper.accessor("customer_id", {
        id: "customer_id",
        header: () => (
          <p className="text-sm font-bold text-gray-900 dark:text-white">Number of Transaction</p>
        ),
        cell: (info: any) => {
            return <>
                <div className="flex items-center">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {info?.row?.original?.total_transactions}
                    </p>
                </div>
            </>
        },
      }),
    ]; 
  
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [data, setData] = React.useState(() => [...defaultData]);
    const [searchVal, setSearchVal] = useState<any>("");
    const [searchGateTexts, setSearchTexts] = useState<string>("");
    const [paymentData, setPaymentData] = useState<any[]>([]);
    const [activeGatewayTransactions, setActiveGatewayTransactions] = useState("");
    useEffect(() => {

      getAllGateways(searchGateTexts)
        .then((data) => {
          setPaymentData(data ?? []);
        })
        .catch((err) => {
        
        })
        .finally(() => {
          
        });

      const processedData = tableData.map((item: any) => {
        let transactions = [];
        if (Array.isArray(item.transactionsList)) {
          try {
            transactions = item.transactionsList.map((txn: string) => JSON.parse(txn));

            const groupedTransactions = transactions.reduce((acc: Record<string, any[]>, txn : any) => {
              if (!txn.gateway_id) return acc;
              
              if (!acc[txn.gateway_id]) {
                acc[txn.gateway_id] = [];
              }
        
              acc[txn.gateway_id].push(txn);
              return acc;
            }, {});

            transactions = groupedTransactions
          } catch (error) {
            console.error("Invalid JSON in transactionsList:", item.transactionsList, error);
          }
        }

        let payment_methods = [];
        if (Array.isArray(item.paymentMethods)) {
          try {
            payment_methods = item.paymentMethods.map((txn: string) => JSON.parse(txn));


            payment_methods = payment_methods
          } catch (error) {
            console.error("Invalid JSON in paymentMethods:", item.paymentMethods, error);
          }
        }
    
        return {
          ...item,
          transactionsList: transactions,
          paymentMethods:payment_methods
        };
      });
      setData(processedData);
    }, [tableData]);
    
    
  
    const table = useReactTable({
      data,
      columns: columns,
      state: {
        pagination,
        sorting,
      },
      onPaginationChange: setPagination,
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      debugTable: true,
    });
  
    const handleValueChange = (e: any) => {
  
      onValueChange(e);
      setSearchVal(e);
    };

    const getGatewayName = (id: any) => {
      const gateway = paymentData.find((item) => item.id === id);
      return gateway ? gateway.name : "Unknown";
    };

    const getCardSVG = (type: string) => {
        switch (type) {
          case "VISA":
            return (
              <img
                className=""
                style={{ maxWidth: "65px" }}
                title={type}
                src={`${visa}`}
                alt={`${type}`}
              />
            );
          case "MasterCard":
            return (
              <img
                style={{ maxWidth: "65px" }}
                title={type}
                src={`${MasterCard}`}
                alt={`${type}`}
              />
            );
          case "Discover":
            return (
              <img
                style={{ maxWidth: "65px" }}
                title={type}
                src={`${Discover}`}
                alt={`${type}`}
              />
            );
          case "Amex":
            return (
              <img
                style={{ maxWidth: "65px" }}
                title={type}
                src={`${Amex}`}
                alt={`${type}`}
              />
            );
          case "MaestroCard":
            return (
              <img
                style={{ maxWidth: "65px" }}
                title={type}
                src={`${MaestroCard}`}
                alt={`${type}`}
              />
            );
          case "DinersClub":
            return (
              <img
                style={{ maxWidth: "65px" }}
                title={type}
                src={`${DinersClub}`}
                alt={`${type}`}
              />
            );
          case "JCB":
            return (
              <img
                style={{ maxWidth: "65px" }}
                title={type}
                src={`${JCB}`}
                alt={`${type}`}
              />
            );
          case "UnionPay":
            return (
              <img
                style={{ maxWidth: "65px" }}
                title={type}
                src={`${UnionPay}`}
                alt={`${type}`}
              />
            );
    
          default:
            return "-";
        }
    };

    const cardPatterns: { [key: string]: RegExp } = {
      VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
      MasterCard: /^5[1-5][0-9]{14}$|^2[2-7][0-9]{14}$/,
      Discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
      Amex: /^3[47][0-9]{13}$/,
      MaestroCard: /^(5018|5020|5038|5893|6304|6759|6761|6762|6763)[0-9]{8,15}$/,
      DinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/,
      UnionPay: /^62\d{12,19}$/
    };

    const getCardType = (cardNumber: string): string => {
      const cleaned = cardNumber.replace(/\D/g, ""); 
    
      for (const [cardType, pattern] of Object.entries(cardPatterns)) {
        if (pattern.test(cleaned)) {
          return cardType;
        }
      }
    
      return "Unknown";
    };

    const openGatewayTransactions = (value : any) => {
      setActiveGatewayTransactions(activeGatewayTransactions === value ? null : value);
    }
    

    return (
      <Card extra={"w-full h-full sm:overflow-auto px-6"}>
        {/* <header className="relative flex items-center justify-between pt-5"> */}
        <header className="relative flex items-center justify-between pt-5 flex-wrap">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            All Customers
          </div>
          {/* <div className=" flex items-start justify-center">
            <select className="mb-3  flex items-center justify-center text-sm font-bold text-gray-900 hover:cursor-pointer dark:!bg-navy-800 dark:text-white">
              <option value="organization">Organization</option>
            </select>
          </div> */}
          <Searchbox onSearch={handleValueChange} />
        </header>
  
        <div className="mt-4  overflow-x-auto scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-xl scrollbar-h-1.5 relative overflow-x-auto shadow-md sm:rounded-lg">
          {props.isLoading ? (
            <DivLoader className="m-5 h-6 w-6 border-indigo-500" />
          ) : (
            <table className="w-full w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 ">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className=""
                  >
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start p-2"
                        >
                          <div className="items-center justify-between text-xs text-gray-200 ">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: "",
                              desc: "",
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody >
                {(table.getRowModel().rows?.length > 0) ? table.getRowModel().rows.map((row) => {
                  return (
                    <React.Fragment key={row.id}>
                      <tr key={row.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ">
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              className="min-w-[150px] border-white/0 py-3  pr-4 p-2 font-bold text-gray-900"
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                      {expandedRow === row.original.customer_id && (
                        <tr className="bg-gray-100">
                          <td colSpan={columns.length} className="p-4 border">
                            <div className="space-y-2">
                            
                              <>
                              <h4 className="font-bold text-navy-700">Gateways</h4>
                              {Object.keys(row.original.transactionsList).length > 0 ? (
                                <div className="border rounded-lg p-3 bg-white shadow">
                                  <table className="w-full w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 ">
                                      <tr>
                                        <th className="border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start p-2">
                                          <div className="items-center justify-between text-xs text-gray-200 ">
                                            <p className="text-sm font-bold text-gray-900 dark:text-white">Gateway Name</p>
                                          </div>
                                        </th>
                                        <th className="border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start p-2">
                                          <div className="items-center justify-between text-xs text-gray-200 ">
                                            <p className="text-sm font-bold text-gray-900 dark:text-white">Customer Id</p>
                                          </div>
                                        </th>
                                        <th className="border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start p-2">
                                          <div className="items-center justify-between text-xs text-gray-200 ">
                                            <p className="text-sm font-bold text-gray-900 dark:text-white">No Of Transactions</p>
                                          </div>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody >
                                      {Object.entries(row.original.transactionsList).map(([gatewayId, transactions]: [string, any]) => (
                                        <React.Fragment key={gatewayId}>
                                          <tr key={gatewayId}  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ">
                                            <td className="min-w-[150px] border-white/0 py-3  pr-4 p-2 font-bold text-gray-900">
                                              <div className="flex items-center gap-2 cursor-pointer" onClick={() => openGatewayTransactions(gatewayId + ":" + row.original.customer_id)}>
                                                {activeGatewayTransactions === (gatewayId + ":" + row.original.customer_id) ? (
                                                  <>
                                                    <AiFillMinusCircle className="w-5 h-5 text-navy-700 dark:text-white" /> {getGatewayName(gatewayId)}
                                                  </>
                                                ) : (
                                                  <>
                                                    <AiFillPlusCircle className="w-5 h-5 text-navy-700 dark:text-white" /> {getGatewayName(gatewayId)}
                                                  </>
                                                )}
                                              </div>
                                            </td>
                                            <td className="min-w-[150px] border-white/0 py-3  pr-4 p-2 font-bold text-gray-900">
                                              -
                                            </td>
                                            <td className="min-w-[150px] border-white/0 py-3  pr-4 p-2 font-bold text-gray-900">
                                              {transactions.length}
                                            </td>
                                          </tr>
                                          {activeGatewayTransactions === (gatewayId + ":" + row.original.customer_id) && (
                                              <tr>
                                                <td colSpan={3} className="p-4 text-gray-900 dark:text-white">
                                                  <div className="space-y-2">
                                                    <table className="w-full w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                                                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 ">
                                                        <tr>
                                                          <th className="border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start p-2">
                                                            <div className="items-center justify-between text-xs text-gray-200 ">
                                                              <p className="text-sm font-bold text-gray-900 dark:text-white">Date</p>
                                                            </div>
                                                          </th>
                                                          <th className="border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start p-2">
                                                            <div className="items-center justify-between text-xs text-gray-200 ">
                                                              <p className="text-sm font-bold text-gray-900 dark:text-white">Amount</p>
                                                            </div>
                                                          </th>
                                                          <th className="border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start p-2">
                                                            <div className="items-center justify-between text-xs text-gray-200 ">
                                                              <p className="text-sm font-bold text-gray-900 dark:text-white">Transaction ID</p>
                                                            </div>
                                                          </th>
                                                          <th className="border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start p-2">
                                                            <div className="items-center justify-between text-xs text-gray-200 ">
                                                              <p className="text-sm font-bold text-gray-900 dark:text-white">Internal ID</p>
                                                            </div>
                                                          </th>
                                                          <th className="border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start p-2">
                                                            <div className="items-center justify-between text-xs text-gray-200 ">
                                                              <p className="text-sm font-bold text-gray-900 dark:text-white">Gateway Name</p>
                                                            </div>
                                                          </th>
                                                          <th className="border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start p-2">
                                                            <div className="items-center justify-between text-xs text-gray-200 ">
                                                              <p className="text-sm font-bold text-gray-900 dark:text-white">DESCRIPTOR</p>
                                                            </div>
                                                          </th>
                                                          <th className="border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start p-2">
                                                            <div className="items-center justify-between text-xs text-gray-200 ">
                                                              <p className="text-sm font-bold text-gray-900 dark:text-white">Card Type</p>
                                                            </div>
                                                          </th>
                                                          <th className="border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start p-2">
                                                            <div className="items-center justify-between text-xs text-gray-200 ">
                                                              <p className="text-sm font-bold text-gray-900 dark:text-white">Status</p>
                                                            </div>
                                                          </th>
                                                          <th className="border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start p-2">
                                                            <div className="items-center justify-between text-xs text-gray-200 ">
                                                              <p className="text-sm font-bold text-gray-900 dark:text-white">Action</p>
                                                            </div>
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody >
                                                        {transactions.map((transaction : any, index : any) => (
                                                          <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 ">
                                                            <td className="min-w-[150px] border-white/0 py-3  pr-4 p-2 font-bold text-gray-900">{moment(transaction.created_at).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                            <td className="min-w-[150px] border-white/0 py-3  pr-4 p-2 font-bold text-gray-900 uppercase">
                                                              {convertToFloat(transaction.amount)} {transaction.currency }
                                                              {transaction?.converted_amount &&
                                                              transaction?.converted_currency && (
                                                                <span>
                                                                  {` (${transaction?.converted_amount} ${transaction?.converted_currency})`}
                                                                </span>
                                                              )}
                                                            </td>
                                                            <td
                                                            className="min-w-[150px] border-white/0 py-3  pr-4 p-2 font-bold text-gray-900">
                                                              <ShortTruncateCopy info={transaction?.et_id} showCopy={true} />
                                                            </td>
                                                            <td className="min-w-[150px] border-white/0 py-3  pr-4 p-2 font-bold text-gray-900">
                                                              <ShortTruncateCopy info={transaction?.id} showCopy={true} />
                                                            </td>
                                                            <td className="min-w-[150px] border-white/0 py-3  pr-4 p-2 font-bold text-gray-900">
                                                              {getGatewayName(transaction.gateway_id)}
                                                            </td>
                                                            <td className="min-w-[150px] border-white/0 py-3  pr-4 p-2 font-bold text-gray-900">
                                                              {transaction?.meta_info?.Descriptor}
                                                            </td>
                                                            <td className="min-w-[150px] border-white/0 py-3  pr-4 p-2 font-bold text-gray-900">
                                                              {transaction?.meta_info?.binInformation
                                                              ? getCardSVG(transaction?.meta_info?.binInformation?.card_type)
                                                              : "-"}
                                                            </td>
                                                            <td className="min-w-[150px] border-white/0 py-3  pr-4 p-2 font-bold text-gray-900">
                                                            <div className="flex items-center gap-1.5 text-sm font-bold">
                                                                {/* <StatusRender
                                                                  status={transaction?.status}
                                                                  value={transaction?.status}
                                                                />  */}

                                                                <CustomToolTip
                                                                  content={
                                                                    transaction?.payment_history?.length > 0 ? (
                                                                      transaction?.payment_history?.map(
                                                                        (hisData: any, index: number) => {
                                                                          let errorMessage = ""
                                                                          if(transaction?.status == 'DECLINED' || transaction?.status == 'ERRORED' || transaction?.status == 'FAILED') {
                                                                            const gatewayResponse = transaction?.gateway_response?.gateway_response;
                                                                            const errorKeys = ["errorMessage", "message", "decline_reason","error_message"];
                                                                            const findLastErrorMessage = (obj: any): string | null => {
                                                                              let lastValue: string | null = null;
                                                                            
                                                                              const search = (data: any) => {
                                                                                if (!data || typeof data !== "object") return;
                                                                            
                                                                                for (const key of Object.keys(data)) {
                                                                                  if (errorKeys.includes(key) && key !== "_content") {
                                                                                    lastValue = data[key]; // Store the last found error message
                                                                                  }
                                                                            
                                                                                  // Recursively search inside nested objects
                                                                                  if (typeof data[key] === "object") {
                                                                                    search(data[key]);
                                                                                  }
                                                                            
                                                                                  // Special handling for `_content`
                                                                                  if (key === "_content" && typeof data[key] === "string") {
                                                                                    try {
                                                                                      const parsedContent = JSON.parse(data[key]);
                                                                            
                                                                                      // Check for `decline_reason` inside the parsed content
                                                                                      if (parsedContent.decline_reason) {
                                                                                        lastValue = parsedContent.decline_reason;
                                                                                      } else {
                                                                                        // If `decline_reason` is not found, check for other error keys
                                                                                        for (const parsedKey of Object.keys(parsedContent)) {
                                                                                          if (errorKeys.includes(parsedKey)) {
                                                                                            lastValue = parsedContent[parsedKey];
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    } catch (error) {
                                                                                      console.error("Failed to parse _content:", error);
                                                                                    }
                                                                                  }
                                                                                }
                                                                              };
                                                                            
                                                                              search(obj);
                                                                              return lastValue;
                                                                            };
                                                                            
                                                                            
                                                                            const lastError = findLastErrorMessage(gatewayResponse);
                                                                            errorMessage = typeof lastError === "string" ? lastError : "";

                                                                          
                                                                          }
                                                                          return (
                                                                            <>
                                                                              <Card className="m-2 shadow " key={index}>
                                                                                <div className="border-1 max-w-[70vh] border  border-white  p-3">
                                                                                  {index == 0 && (
                                                                                    <p className="">
                                                                                      Created at {hisData?.created_at} by API
                                                                                      call from [{hisData?.client}
                                                                                      {" - "}
                                                                                      {hisData?.ip}] with status{" "}
                                                                                      {hisData?.status}

                                                                                      {["DECLINED", "ERRORED", "FAILED"].includes(hisData?.status) && (
                                                                                        <>
                                                                                          <br />
                                                                                          Reason : {errorMessage !== "" ? errorMessage : "Reason not mentioned by the gateway"}
                                                                                        </>
                                                                                      )}
                                                                                    </p>
                                                                                  )}

                                                                                  <div className="">
                                                                                    {hisData?.status == "CHARGEBACK" &&
                                                                                      index != 0 && (
                                                                                        <div className="">
                                                                                          <p>
                                                                                            Status change from{" "}
                                                                                            {hisData?.previous_status} to{" "}
                                                                                            {hisData?.status} at{" "}
                                                                                            {hisData?.status_changed_date} by{" "}
                                                                                            {hisData?.client
                                                                                              ? `System Api Call ${
                                                                                                  roleData?.[0]?.payment
                                                                                                    ?.value
                                                                                                    ?.payment_show_method_name
                                                                                                    ? hisData?.gateway_name
                                                                                                    : transaction?.gateway_id || ""
                                                                                                }`
                                                                                              : `${
                                                                                                  hisData?.first_name || ""
                                                                                                } ${
                                                                                                  hisData?.last_name || ""
                                                                                                }`}
                                                                                          </p>
                                                                                          <p>
                                                                                            ARN Number : {hisData?.arn || "-"}
                                                                                          </p>
                                                                                          <p>
                                                                                            Charge Back Reason :{" "}
                                                                                            {hisData?.reason || "-"}
                                                                                          </p>
                                                                                        </div>
                                                                                      )}
                                                                                    {hisData?.status != "CHARGEBACK" &&
                                                                                      index != 0 && (
                                                                                        <p className=" ">
                                                                                          Status change from{" "}
                                                                                          {hisData?.previous_status} to{" "}
                                                                                          {hisData?.status} at{" "}
                                                                                          {hisData?.status_changed_date} by{" "}
                                                                                          {hisData?.client
                                                                                            ? `System Api Call ${
                                                                                                roleData?.[0]?.payment?.value
                                                                                                  ?.payment_show_method_name
                                                                                                  ? hisData?.gateway_name
                                                                                                  : transaction?.gateway_id || ""
                                                                                              }`
                                                                                            : `${hisData?.first_name || ""} ${
                                                                                                hisData?.last_name || ""
                                                                                              }`}

                                                                                              
                                                                                            {["DECLINED", "ERRORED", "FAILED"].includes(hisData?.status) && (
                                                                                              <>
                                                                                                <br />
                                                                                                Reason : {errorMessage !== "" ? errorMessage : "Reason not mentioned by the gateway"}
                                                                                              </>
                                                                                            )}
                                                                                          {/* Status change from {hisData?.previous_status} to {hisData?.status} at {hisData?.status_changed_date} by {hisData?.client ? `System Api Call  ${hisData?.first_name || ""} ${hisData?.last_name || ""}` : ""} */}
                                                                                        </p>
                                                                                      )}
                                                                                  </div>
                                                                                </div>
                                                                              </Card>
                                                                            </>
                                                                          );
                                                                        }
                                                                      )
                                                                    ) : (
                                                                      <span className="">No history available.</span>
                                                                    )
                                                                  }
                                                                  trigger={
                                                                    <button className="flex items-center gap-1.5">
                                                                      <StatusRender
                                                                        status={transaction?.status}
                                                                        value={transaction?.status}
                                                                      />
                                                                    </button>
                                                                  }
                                                                  header={"Status History"}
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="min-w-[150px] border-white/0 py-3  pr-4 p-2 font-bold text-gray-900">
                                                              <AdvancedInfoModal
                                                                info={transaction}
                                                                client_is_deleted={false}
                                                              />
                                                            </td>

                                                          </tr>
                                                        ))}
                                                      </tbody> 
                                                    </table>
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </React.Fragment>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <div className="border rounded-lg p-3 bg-white shadow">
                                  <p className="font-bold text-navy-700 text-center">No transactions available.</p>
                                </div>
                              )}
                              <h4 className="font-bold text-navy-700">Payment Methods</h4>
                              {Object.keys(row.original.paymentMethods).length > 0 ? (
                                <div className="border rounded-lg p-3 bg-white shadow">
                                  <table className="w-full w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 ">
                                      <tr>
                                        <th className="border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start p-2">
                                          <div className="items-center justify-between text-xs text-gray-200 ">
                                            <p className="text-sm font-bold text-gray-900 dark:text-white">Created Date</p>
                                          </div>
                                        </th>
                                        <th className="border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start p-2">
                                          <div className="items-center justify-between text-xs text-gray-200 ">
                                            <p className="text-sm font-bold text-gray-900 dark:text-white">Payment method id</p>
                                          </div>
                                        </th>
                                        <th className="border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start p-2">
                                          <div className="items-center justify-between text-xs text-gray-200 ">
                                            <p className="text-sm font-bold text-gray-900 dark:text-white">Last 4</p>
                                          </div>
                                        </th>
                                        <th className="border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start p-2">
                                          <div className="items-center justify-between text-xs text-gray-200 ">
                                            <p className="text-sm font-bold text-gray-900 dark:text-white">Type</p>
                                          </div>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody >
                                      {Object.entries(row.original.paymentMethods).map(([key, methods]: [string, any]) => {
                                        return (
                                          <React.Fragment key={key}>
                                            <tr key={key} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                              <td className="min-w-[150px] border-white/0 py-3 pr-4 p-2 font-bold text-gray-900">
                                                {methods.created_at ? moment(methods.created_at).format("YYYY-MM-DD HH:mm:ss") : "No Date Available"}
                                              </td>
                                              <td className="min-w-[150px] border-white/0 py-3 pr-4 p-2 font-bold text-gray-900">
                                                <ShortTruncateCopy info={methods.payment_method_id} showCopy={true} />
                                              </td>
                                              <td className="min-w-[150px] border-white/0 py-3 pr-4 p-2 font-bold text-gray-900">
                                                {methods.card_number.slice(-4)}
                                              </td>
                                              <td className="min-w-[150px] border-white/0 py-3 pr-4 p-2 font-bold text-gray-900">
                                                {getCardSVG(getCardType(methods.card_number))}
                                              </td>
                                              
                                            </tr>
                                          </React.Fragment>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <div className="border rounded-lg p-3 bg-white shadow">
                                  <p className="font-bold text-navy-700 text-center">No payment methods available.</p>
                                </div>
                              )}
                              </>
                            

                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                }) :
  
                  <tr>
                    <td colSpan={6} >
                      <p className="text-center p-4" >No records found.</p>
                    </td>
  
                  </tr>
  
                }
  
  
              </tbody>
            </table>
          )}
        </div>
  
        <Pagination
          setPage={setPage}
          page={page}
          totalpage={totalpage}
          currentPage={currentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          arraySize={[50, 100, 200]}
        />
      </Card>
    );
  }
  
  export default CustomerTable;
  const columnHelper = createColumnHelper<ClientRowObj>();
  